<template>
  <div class="detail-two list-scroll" v-loading="loadingDetail">
    <h1 class="detail-two-title">{{ detail.course_name }}</h1>
    <div class="detail-two-divider"></div>
    <div class="detail-two-time">发布时间：{{ detail.course_time }}</div>
    <!-- 课程简介提纲 -->
    <div class="detail-two-type">课程简介提纲</div>
    <div class="detail-two-content body" v-html="detail.course_body"></div>
    <!-- 课程授课专家 -->
    <div class="detail-two-type">课程授课专家</div>
    <div class="detail-two-content techer">
      <img
        :src="baseUrl + (detail.course_techerheadimg && detail.course_techerheadimg.file_path)"
        alt=""
      />
      <div class="teacher-detail">
        <p class="p1">姓名：{{ detail.course_techername || "--" }}</p>
        <p class="p2">简介：{{ detail.course_techertitle || "--" }}</p>
      </div>
    </div>
    <!-- 图片/视频/文件 -->
    <!-- <template v-if="detail.course_ivtype === 2"> -->
      <div class="detail-two-type">课程资料</div>
      <template v-if="detail.file_video">
      <!-- <div class="detail-two-type">课程视频</div> -->
      <div class="detail-two-content img">
        <template v-if="detail.file_img && detail.file_img.length > 0">
          <img :src="baseUrl + f.file_path" v-for="(f, i) in detail.file_img" :key="i" />
        </template>
      </div>      
      <div class="detail-two-content">
        <template v-if="detail.file_video && detail.file_video.length > 0">
          <video
            :src="baseUrl + f.file_path"
            v-for="(f, i) in detail.file_video"
            controls
            :key="i"
          ></video>
        </template>
      </div>
    <!-- </template> -->
    <!-- <template v-if="detail.course_ivtype === 1"> -->
      <!-- <div class="detail-two-type">课程文件</div> -->
      <div class="detail-two-content file">
        <template v-if="detail.file_file && detail.file_file.length > 0">
          <div v-for="(l, i) in detail.file_file" :key="i" class="file-item">
            <span>{{l.file_name}}</span>
            <el-button size="mini" @click="downloadFile(l.file_path)"
              >下载</el-button
            >
          </div>
        </template>
      </div>
    </template>
    <template v-else>
      <div class="detail-two-content">
        <span>暂无资料</span>
      </div>
    </template>
    <!-- </template> -->
    <!-- 评价 -->
    <div class="detail-two-type">评价与反馈</div>
    <div class="detail-two-content evaluation">
      <div class="evaluation-left">
        <img src="@/assets/default-avatar.jpg" alt="" />
      </div>
      <div class="evaluation-right">
        <el-input
          type="textarea"
          placeholder="请输入内容"
          resize="none"
          v-model="mainComment"
        ></el-input>
        <div class="evaluation-btns">
          <div class="btns-left">
            <!-- <i class="el-icon-picture-outline"></i>
            <input type="file" name="" id="" class="upload-img" />
            <i class="el-icon-camera"></i> -->
          </div>
          <div class="btns-right">
            <span @click="submitComment()">发表</span>
          </div>
        </div>
      </div>
    </div>
    <!-- 评论列表 -->
    <div class="comment-box" v-loading="loadingComment">
      <div class="comment-title">
        <div class="comment-title-left">
          评论{{ commentsDetail.list.length }}
        </div>
        <div class="comment-title-right">
          <span>时间正序</span>
          <span>时间倒序</span>
          <span>同感正序</span>
        </div>
      </div>
      <div class="comment-list">
        <template v-if="commentsDetail.list.length > 0">
          <div class="list-item" v-for="l in commentsDetail.list" :key="l.id">
            <div class="list-item-left">
              <img src="@/assets/user-head.jpg" alt="" />
            </div>
            <div class="list-item-area">
              <div class="item-area-user">
                <!-- <img src="@/assets/user-1.jpg" alt="" /> -->
                <span>{{ l.user_name }}</span>
                <span>{{ l.add_time }}</span>
              </div>
              <div class="item-area-comment">
                {{ l.comment_body }}
              </div>
              <div class="item-area-btns">
                <div class="btns-left">
                  <el-button size="mini" v-show="false">回复</el-button>
                </div>
                <div class="btns-right">
                  <span :class="{ like: inArray(l.id, 1) }" @click="likeCourseComment(l.id, 1)" >
                    <i class="iconfont">&#xe673;</i>{{ l.like_user ? l.like_user.length : 0 }}
                  </span>
                  <span :class="{ dislike: inArray(l.id, 2) }" @click="likeCourseComment(l.id, 2)" >
                    <i class="iconfont">&#xe666;</i>{{ l.diss_user ? l.diss_user.length : 0 }}
                  </span>
                </div>
              </div>
              <div class="item-area-reply" v-if="commentCId === l.id">
                <el-input v-model="secComment"></el-input>
                <el-button
                  size="mini"
                  type="primary"
                  @click="submitComment(l.id)"
                >
                  回复</el-button
                >
              </div>
            </div>
          </div>
          <el-pagination
            class="list-pagination"
            background
            :current-page.sync="commentsDetail.pageindex"
            :page-size="commentsDetail.pagesize"
            :total="commentsDetail.total"
            layout="prev, pager, next"
            prev-text="上一页"
            next-text="下一页"
            @current-change="getCommentsList(false)"
          >
          </el-pagination>
        </template>
        <template v-else>
          <p class="empty">暂无评论~</p>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import { getCourseMes, addCourseComment, getCourseCommentList, likeCourseComment} from "@/api/base.js";
import { Loading } from 'element-ui';

export default {
  name: "DetailTwo",
  data() {
    return {
      loadingDetail: false,
      loadingComment: false,
      searchObj: {
        moduleCode: "",
        typeId: 0,
        cId: 0,
      },
      detail: {},
      mainComment: "",
      secComment: "",
      commentCId: -1,
      commentsDetail: {
        pageindex: 1,
        pagesize: 10,
        total: 0,
        list: [],
      },
    };
  },
  created() {
    let token = window.localStorage.getItem("wildanimals_token");
    if (!token) {
      this.$message.warning("登录后才能查看，快去登录吧~");
      this.$router.push({ name: "Login" });
      return;
    }
    const { query } = this.$route;
    this.searchObj.moduleCode = query.module_code;
    this.searchObj.cId = query.cid;
    this.getCourseMes();
    this.getCommentsList(true); // 评论列表
  },
  mounted() {},
  methods: {
    // 获取详情
    getCourseMes() {
      let loadingInstance = Loading.service({ fullscreen: true });
      // this.loadingDetail = true;
      let params = {
        id: this.searchObj.cId,
      };
      getCourseMes(params)
        .then((res) => {
          const { data } = res;

          console.log('data',data.course_file);
          if(Array.isArray(data.course_file) && data.course_file.length > 0){
            let file_img = []
            let file_video = []
            let file_file = []

            data.course_file.forEach(el=>{
              if(el.file_type === 1){
                file_img.push(el)
              } else if(el.file_type === 2){
                file_video.push(el)
              } else if(el.file_type === 3){
                file_file.push(el)
              }
            })

            data['file_img'] = file_img
            data['file_video'] = file_video
            data['file_file'] = file_file
          }
          this.detail = data;
          this.$nextTick(()=>{
            // this.loadingDetail = false;
            loadingInstance.close();
          })

          this.$emit("clickItem", data.course_name);
        })
        .catch((err) => {
          console.log(err);
          // this.loadingDetail = false;
          loadingInstance.close();
        })
        .finally(() => {
          // this.loadingDetail = false;
        });
    },
    // 获取评论
    getCommentsList(init = false) {
      this.loadingComment = true;
      if (init) {
        this.commentsDetail.pageindex = 1;
        this.commentsDetail.total = 0;
        this.commentsDetail.list = [];
      }
      let params = {
        id: this.searchObj.cId,
        pageindex: this.commentsDetail.pageindex,
        pagesize: this.commentsDetail.pagesize,
      };
      getCourseCommentList(params)
        .then((res) => {
          const { data } = res;
          this.commentsDetail.list = data.data;
          this.commentsDetail.total = data.dataCount;
        })
        .finally(() => {
          this.loadingComment = false;
        });
    },
    // 下载文件
    downloadFile(path) {
      window.open(this.baseUrl + path);
    },
    // 提交评论
    submitComment(cid) {
      let index = -1;
      if (cid) {
        // 子评论
        index = this.commentsList.findIndex((el) => el.id === cid);
        if (!this.commentsList[index].comment) {
          this.$message.warning("请输入内容");
          return;
        }
      } else {
        // 主评论
        if (!this.mainComment) {
          this.$message.warning("请输入内容");
          return;
        }
      }

      let data = {
        course_id: this.searchObj.cId,
        comment_body: this.mainComment,
      };
      addCourseComment(data)
        .then((res) => {
          if (res.code === 200) {
            this.$message.success("评论成功，待审核后显示");
            this.getCommentsList(true);
          } else {
            this.$message.error(res.message);
          }
        })
        .catch((err) => {
          console.log(err);
        });

      if (cid) {
        // 子评论
        this.commentsList[index].show = false;
        this.commentsList[index].comment = "";
      } else {
        this.mainComment = "";
      }
    },
    // 点赞
    likeCourseComment(id, like) {
      if (this.inArray(id, like)) return;
      let params = {
        id,
        like,
      };
      likeCourseComment(params)
        .then((res) => {
          if (res.code === 200) {
            this.$message.success("操作成功！");
            this.getCommentsList();
          } else {
            this.$message.error(res.message);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    inArray(id, type) {
      // id:评论id  type：1喜欢 2不喜欢

      if (type === 1) {
        let item = this.commentsDetail.list.find((el) => el.id === id);
        if (item && item.like_user) {
          let exist = item.like_user.some((id) => id === Number(this.userId));
          return exist;
        } else {
          return false;
        }

        // if (item && item.like_user) {
        //   let flag = item.like_user.some((id) => id === Number(this.userId));
        //   if (flag) {
        //     return require("@/assets/icon/a-like.png");
        //   } else {
        //     return require("@/assets/icon/like.png");
        //   }
        // } else {
        //   return require("@/assets/icon/like.png");
        // }
      }
      if (type === 2) {
        let item = this.commentsDetail.list.find((el) => el.id === id);
        if (item && item.diss_user) {
          let exist = item.diss_user.some((id) => id === Number(this.userId));
          return exist;
        } else {
          return false;
        }
        // let item = this.commentsList.find((el) => el.id === id);
        // if (item && item.diss_user) {
        //   let flag = item.diss_user.some((id) => id === Number(this.userId));
        //   if (flag) {
        //     return require("@/assets/icon/a-dislike.png");
        //   } else {
        //     return require("@/assets/icon/dislike.png");
        //   }
        // } else {
        //   return require("@/assets/icon/dislike.png");
        // }
      }
      return "";
    },
  },
};
</script>

<style lang="scss" scoped>
@media only screen and (min-width: 769px) {
  .detail-two {
    max-height: calc(100% - 46px) !important;
    .detail-two-title {
      font-size: 22px;
    }
    .detail-two-content {
    }
  }
}

@media only screen and (max-width: 768px) {
  .detail-two {
    max-height: 100% !important;
    .detail-two-title {
      font-size: 18px;
    }
    .detail-two-content {
    }
  }
}
.detail-two {
  overflow-y: auto;

  .detail-two-title {
    text-align: center;
    margin-top: 20px;
  }

  .detail-two-divider {
    height: 1px;
    background: #343434;
    margin: 10px 0;
  }

  .detail-two-time {
    color: #818181;
    font-size: 12px;
    text-align: center;
  }

  .detail-two-type {
    font-size: 18px;
    font-family: SourceHanSansCN;
    font-weight: bold;
    color: #000000;
    padding-left: 6px;
    border-left: 4px solid #007cf9;
    margin-top: 30px;
  }

  .detail-two-content {
    margin-top: 16px;

    &.body {
      font-size: 14px;
      color: #5a5a5a;
      text-indent: 2em;
      /deep/ img {
        max-width: 100%;
        height: auo;
      }
    }
    &.techer {
      // width: 180px;
      min-height: 80px;
      // background: #d1e7ff;
      // border-radius: 10px;
      // transform: translateX(50%);
      display: flex;
      img {
        width: 80px;
        height: 80px;
        border-radius: 50%;
        // transform: translateX(-50%);
      }
      .teacher-detail {
        font-size: 16px;
        font-family: SourceHanSansCN;
        font-weight: 400;
        color: #000000;
        margin-left: 8px;
        p {
          // height: 50%;
          font-size: 14px;
          display: flex;
          align-items: center;
          &.p1{
            font-weight:700;
          }
          &.p2{
            margin-top:4px;
          }
        }
      }
    }
    video {
      // width: 49%;
      width:100%;
      max-height: 420px;
      margin-right: 2%;

      // &:nth-child(2n) {
      //   margin-right: 0;
      // }
    }
    &.img{
      img{
        width:100%;
        max-height: 420px;
        object-fit: contain;
      }
    }
    &.file {
      .file-item {
        display: flex;
        align-items: center;
        margin-bottom: 6px;

        span {
          margin-right: 10px;
        }
      }
    }
    &.evaluation {
      display: flex;
      .evaluation-left {
        width: 60px;
        margin-right: 20px;
        img {
          width: 100%;
          border-radius: 50%;
        }
      }
      .evaluation-right {
        flex: 1;
        border: 1px solid #cacaca;
        padding: 6px 10px;

        .el-textarea {
          height: 100px;
          /deep/ .el-textarea__inner {
            height: 100%;
            border: 0 none;
            padding: 0;
          }
        }

        .evaluation-btns {
          display: flex;
          justify-content: space-between;
          align-items: center;
          height: 26px;
          .btns-left {
          }
          .btns-right {
            span {
              color: #fff;
              background: #007cf9;
              padding: 4px 10px;
              font-size: 12px;
              cursor: pointer;
            }
          }
        }
      }
    }
  }

  .comment-box {
    padding: 0 10px 20px;
    margin-top: 20px;
    .comment-title {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 10px 0;
      border-bottom: 1px solid #ccc;
      .comment-title-left {
      }
      .comment-title-right {
        display: none;
        span {
          font-size: 12px;
          margin-left: 6px;
        }
      }
    }
    .comment-list {
      .list-item {
        display: flex;
        padding: 10px 0;
        border-bottom: 1px solid #ccc;
      }
      .list-item-left {
        width: 50px;
        margin-right: 12px;
        img {
          width: 100%;
          border-radius: 50%;
          border: 1px solid #ccc;
        }
      }
      .list-item-area {
        flex: 1;
        .item-area-user {
          display: flex;
          align-items: center;
          img {
            width: 26px;
            height: 26px;
            border-radius: 50%;
            border: 1px solid #ccc;
          }
          span {
            margin-left: 8px;
            font-size: 14px;
            &:nth-child(even) {
              font-weight: 700;
              font-size: 16px;
            }
          }
        }
        .item-area-comment {
          padding: 6px 0 10px;
          font-size: 14px;
        }
        .item-area-btns {
          display: flex;
          justify-content: space-between;
          align-items: center;
          .btns-left {
          }
          .btns-right {
            span {
              margin-left: 6px;
              &.like {
                color: blue;
              }
              &.dislike {
                color: red;
              }
              i {
                margin-right: 2px;
              }
            }
          }
        }
        .item-area-reply {
          display: flex;
          margin-top: 8px;
          .el-input {
            margin-right: 8px;
          }
        }
      }
      .list-pagination {
        margin-top: 20px;
        text-align: center;
        /deep/ .btn-prev,
        /deep/ .btn-next {
          padding: 0 6px;
        }
      }

      >.empty{
        text-align:center;
        padding:30px 0;
        font-size:12px;
      }
    }
  }
}
</style>